.shimmer-br {
  border-radius: 8px;
}
.shimmer-w80 {
  width: 60%;
}
.shimmer-card {
  border: 2px solid #fff;
  box-shadow:0 0 10px 0 #a9a9a9;
  padding: 30px 40px;
  width: 80%;
  margin: 50px auto;
}

.shimmer-wrapper {
  width: 0;
  animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}
.shimmer-profilePic {
  height: 65px;
  width: 65px;
  border-radius: 50%;
}
.shimmer-comment {
  height: 15px;
  background: #777;
  margin-top: 20px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}


.shimmer-animate {
  animation : shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}