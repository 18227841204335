.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.2rem;
}

.react-datepicker {
  font-size: 1rem;
}
.react-datepicker__header > div {
  font-size: 1.2rem;
}
.react-datepicker__time-container  {
  font-size: 0.8rem;
}

.react-datepicker-popper {
  z-index: 3;
}