.sweet-alert-custom-content {
  padding: 0 !important;
}

.sweet-alert-custom-popup {
  padding: 0 !important;
  background: unset !important;
}

.sweet-alert-custom-content-fullscreen {
  padding: 0 !important;
  height: 100% !important;
}

.sweet-alert-custom-popup-fullscreen {
  padding: 0 !important;
  background: unset !important;
  width: 100% !important;
  height: 100% !important;
}